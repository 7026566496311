<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
         style="width: min-content"
    >
      <!-- Bookmarks Container -->
      <bookmarks />
    </div>
    <div v-if="isPermission" class="button-container">
      <div
        class="avtBtn"
        type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="clickHandler(1)"
      >
        <BAvatar :class="{'flashing': isQuestionFlashing1}" variant="dark" :badge="`${alarmCnt1}`" badge-variant="light">충전</BAvatar>
      </div>
      <div
        class="avtBtn"
        type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="clickHandler(2)"
      >
        <BAvatar :class="{'flashing': isQuestionFlashing2}" variant="dark" :badge="`${alarmCnt2}`" badge-variant="light">환전</BAvatar>
      </div>
      <div
        class="avtBtn"
        type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="clickHandler(3)"
      >
        <BAvatar :class="{'flashing': isQuestionFlashing3}" variant="dark" :badge="`${alarmCnt3}`" badge-variant="light">문의</BAvatar>
      </div>
      <div
        class="avtBtn"
        type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="clickHandler(4)"
      >
        <BAvatar :class="{'flashing': isQuestionFlashing4}" variant="dark" :badge="`${alarmCnt4}`" badge-variant="light">신규</BAvatar>
      </div>
      <div
        class="avtBtn"
        type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="clickHandler(5)"
      >
        <BAvatar :class="{'flashing': isQuestionFlashing5}" variant="dark" :badge="`${alarmCnt5}`" badge-variant="light">환급</BAvatar>
      </div>
      <div
        class="avtBtn"
        type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="clickHandler(6)"
      >
        <BAvatar :class="{'flashing': isQuestionFlashing6}" variant="dark" :badge="`${alarmCnt6}`" badge-variant="light">총환</BAvatar>
      </div>
      <div
        class="avtBtn"
        type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="clickHandler(7)"
      >
        <BAvatar :class="{'flashing': isQuestionFlashing7}" variant="dark" :badge="`${alarmCnt7}`" badge-variant="light">고액</BAvatar>
      </div>
      <div
        class="avtBtn"
        type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="clickHandler(8)"
      >
        <BAvatar :class="{'flashing': isQuestionFlashing8}" variant="dark" :badge="`${alarmCnt8}`" badge-variant="light">공지</BAvatar>
      </div>
      <div
        class="avtBtn"
        type="button" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="clickHandler(9)"
      >
        <BAvatar :class="{'flashing': isQuestionFlashing9}" variant="dark" :badge="`${alarmCnt9}`" badge-variant="light">비회원</BAvatar>
      </div>
      <div class="pr-2"></div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar class="d-none d-lg-block" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BAvatar,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  FETCH_REQUEST_ALARMS_DEPOSIT_CNT,
  FETCH_REQUEST_ALARMS_EXCHANGE_CNT,
  FETCH_REQUEST_ALARMS_SIGNUP_CNT,
  FETCH_REQUEST_ALARMS_BET_CNT,
  FETCH_SET_NOTICE_ADMIN_CNT,
  FETCH_REQUEST_ALARMS_CNT,
  FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_CNT,
} from '@/store/notice/action'
import {
  FETCH_MESSAGES_CNT,
}from '@/store/messages/action'
import {MENU_SET_SITE_ADMIN_MENU_PERMISSION} from '@/store/settings/action'

const settingsStore = createNamespacedHelpers('settingsStore')
const notificationStore = createNamespacedHelpers('notification')
const noticeStore = createNamespacedHelpers('noticeStore')
const messagesStore = createNamespacedHelpers('messagesStore')

export default {
  components: {
    BLink,
    BAvatar,
    BNavbarNav,
    Bookmarks,
    SearchBar,
    DarkToggler,
    UserDropdown,
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    this.fetchData()
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      isQuestionFlashing1: false, // 충전 아바타 깜빡임 상태
      isQuestionFlashing2: false, // 환전 아바타 깜빡임 상태
      isQuestionFlashing3: false, // 문의 아바타 깜빡임 상태
      isQuestionFlashing4: false, // 신규 아바타 깜빡임 상태
      isQuestionFlashing5: false, // 환급 아바타 깜빡임 상태
      isQuestionFlashing6: false, // 총환 아바타 깜빡임 상태
      isQuestionFlashing7: false, // 고액 아바타 깜빡임 상태
      isQuestionFlashing8: false, // 공지 아바타 깜빡임 상태
      isQuestionFlashing9: false, // 공지 아바타 깜빡임 상태
      alarmCnt1: 0,
      alarmCnt2: 0,
      alarmCnt3: 0,
      alarmCnt4: 0,
      alarmCnt5: 0,
      alarmCnt6: 0,
      alarmCnt7: 0,
      alarmCnt8: 0,
      alarmCnt9: 0,
      lastNotificationCount: 0, // 마지막으로 확인된 알림의 수
      isPermission: false,
    }
  },
  computed: {
    ...notificationStore.mapGetters([
      'notifications',
      'countNotifications',
    ]),
    ...noticeStore.mapGetters({
      fetchRequestAlarmsDepositCnt: 'fetchRequestAlarmsDepositCnt',
      fetchRequestAlarmsExchangeCnt: 'fetchRequestAlarmsExchangeCnt',
      fetchRequestAlarmsSignupCnt: 'fetchRequestAlarmsSignupCnt',
      fetchRequestAlarmsBetCnt: 'fetchRequestAlarmsBetCnt',
      fetchSetNoticeAdminCnt: 'fetchSetNoticeAdminCnt',
      fetchRequestAlarmsCnt: 'fetchRequestAlarmsCnt',
      fetchRequestAlarmsBranchCashoutCnt: 'fetchRequestAlarmsBranchCashoutCnt',
    }),
    ...messagesStore.mapGetters({
      fetchMessagesCnt: 'fetchMessagesCnt',
    }),
    ...settingsStore.mapGetters({
      menuSetSiteAdminMenuPermission: 'menuSetSiteAdminMenuPermission',
    }),
  },
  watch: {
    async notifications(newVal) {
      const lastNotification = newVal[newVal.length - 1]
      console.log('lastNotification ::', lastNotification)

      // 알림 유형에 따라 이동할 라우터 설정
      let targetRoute = null
      if (lastNotification.avatar === '충전') {
        targetRoute = { name: 'deposit-list' }
      } else if (lastNotification.avatar === '환전') {
        targetRoute = { name: 'exchange-list' }
      } else if (lastNotification.avatar === '문의') {
        targetRoute = { name: 'messages-history-user' }
      } else if (lastNotification.avatar === '신규') {
        targetRoute = { name: 'users-Signup-list' }
      } else if (lastNotification.avatar === '환급') {
        targetRoute = { name: 'refund-hit-result' }
      } else if (lastNotification.avatar === '총환') {
        targetRoute = { name: 'branch-cashout-list' }
      } else if (lastNotification.avatar === '고액') {
        targetRoute = { name: 'refund-hit-result', params: { targetTab: '대기함', targetOption: 'YET' } }
      } else if (lastNotification.avatar === '공지') {
        targetRoute = { name: 'notice-admin' }
      } else if (lastNotification.avatar === '비회원') {
        targetRoute = { name: 'messages-guest' }
      }

      // Toast Popup
      if (lastNotification.title !== '처리') {
        this.showToast(lastNotification.title, lastNotification.subtitle, 'top-center', targetRoute)
      }

      const mySite = this.authSite
      if (lastNotification.avatar === '충전') {
        await this.$fetchRequestAlarmsDepositCnt({
          site: mySite,
          confirmed: 'REQUEST',
        })
        this.alarmCnt1 = this.fetchRequestAlarmsDepositCnt
      }
      if (lastNotification.avatar === '환전') {
        await this.$fetchRequestAlarmsExchangeCnt({
          site: mySite,
          confirmed: 'REQUEST',
        })
        this.alarmCnt2 = this.fetchRequestAlarmsExchangeCnt
      }
      if (lastNotification.avatar === '문의') {
        await this.$fetchMessagesCnt({
          site: mySite,
          userid: this.userData.userid,
        })
        this.alarmCnt3 = this.fetchMessagesCnt
      }
      if (lastNotification.avatar === '신규') {
        await this.$fetchRequestAlarmsSignupCnt({
          site: mySite,
          confirmed: 'REQUEST',
        })
        this.alarmCnt4 = this.fetchRequestAlarmsSignupCnt
      }
      if (lastNotification.avatar === '환급') {
        await this.$fetchRequestAlarmsBetCnt({
          site: mySite,
          confirmed: 'REQUEST',
        })
        this.alarmCnt5 = this.fetchRequestAlarmsBetCnt
      }
      if (lastNotification.avatar === '총환') {
        await this.$fetchRequestAlarmsBranchCashoutCnt({
          site: mySite,
          confirmed: 'REQUEST',
        })
        this.alarmCnt6 = this.fetchRequestAlarmsBranchCashoutCnt
      }
      if (lastNotification.avatar === '고액') {
        this.alarmCnt7 += 1
        localStorage.removeItem('alarmCnt7')
        localStorage.setItem('alarmCnt7', this.alarmCnt7)
      }
      if (lastNotification.avatar === '공지') {
        await this.$fetchSetNoticeAdminCnt({
          site: mySite,
        })
        this.alarmCnt8 = this.fetchSetNoticeAdminCnt
      }
      if (lastNotification.avatar === '비회원') {
        await this.$fetchRequestAlarmsCnt({
          site: mySite,
          result: '대기',
        })
        this.alarmCnt9 = this.fetchRequestAlarmsCnt
      }

      this.isQuestionFlashing1 = this.alarmCnt1 > 0
      this.isQuestionFlashing2 = this.alarmCnt2 > 0
      this.isQuestionFlashing3 = this.alarmCnt3 > 0
      this.isQuestionFlashing4 = this.alarmCnt4 > 0
      this.isQuestionFlashing5 = this.alarmCnt5 > 0
      this.isQuestionFlashing6 = this.alarmCnt6 > 0
      this.isQuestionFlashing7 = this.alarmCnt7 > 0
      this.isQuestionFlashing8 = this.alarmCnt8 > 0
      this.isQuestionFlashing9 = this.alarmCnt9 > 0
    },
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsDepositCnt: FETCH_REQUEST_ALARMS_DEPOSIT_CNT,
      $fetchRequestAlarmsExchangeCnt: FETCH_REQUEST_ALARMS_EXCHANGE_CNT,
      $fetchRequestAlarmsSignupCnt: FETCH_REQUEST_ALARMS_SIGNUP_CNT,
      $fetchRequestAlarmsBetCnt: FETCH_REQUEST_ALARMS_BET_CNT,
      $fetchSetNoticeAdminCnt: FETCH_SET_NOTICE_ADMIN_CNT,
      $fetchRequestAlarmsCnt: FETCH_REQUEST_ALARMS_CNT,
      $fetchRequestAlarmsBranchCashoutCnt: FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_CNT,
    }),
    ...messagesStore.mapActions({
      $fetchMessagesCnt: FETCH_MESSAGES_CNT,
    }),
    ...settingsStore.mapActions({
      $menuSetSiteAdminMenuPermission: MENU_SET_SITE_ADMIN_MENU_PERMISSION,
    }),
    async fetchData() {
      const mySite = this.authSite
      try {
        await this.$menuSetSiteAdminMenuPermission({
          userid: this.userData.userid,
        })
      } catch (error) {
        console.error('Failed to fetch admin settings:', error)
      } finally {
        // Set loading to false after fetching data
        if (this.menuSetSiteAdminMenuPermission[0].authBasicAdmin === 'Y'
          || this.menuSetSiteAdminMenuPermission[0].authMasterAdmin === 'Y'
          || this.menuSetSiteAdminMenuPermission[0].authSuperAdmin === 'Y') {
          this.isPermission = true
        } else {
          this.isPermission = false
        }
      }
      await this.$fetchRequestAlarmsDepositCnt({
        site: mySite,
        confirmed: 'REQUEST',
      })
      await this.$fetchRequestAlarmsExchangeCnt({
        site: mySite,
        confirmed: 'REQUEST',
      })
      await this.$fetchMessagesCnt({
        site: mySite,
        userid: this.userData.userid,
      })
      await this.$fetchRequestAlarmsSignupCnt({
        site: mySite,
        confirmed: 'REQUEST',
      })
      await this.$fetchRequestAlarmsBetCnt({
        site: mySite,
        confirmed: 'REQUEST',
      })
      await this.$fetchSetNoticeAdminCnt({
        site: mySite,
      })
      await this.$fetchRequestAlarmsCnt({
        site: mySite,
        result: '대기',
      })
      await this.$fetchRequestAlarmsBranchCashoutCnt({
        site: mySite,
        confirmed: 'REQUEST',
      })
      this.alarmCnt1 = this.fetchRequestAlarmsDepositCnt
      this.alarmCnt2 = this.fetchRequestAlarmsExchangeCnt
      this.alarmCnt3 = this.fetchMessagesCnt
      this.alarmCnt4 = this.fetchRequestAlarmsSignupCnt
      this.alarmCnt5 = this.fetchRequestAlarmsBetCnt
      this.alarmCnt6 = this.fetchRequestAlarmsBranchCashoutCnt
      // this.alarmCnt7 = 실시간 휘발성으로 별도 조회 없음
      this.alarmCnt8 = this.fetchSetNoticeAdminCnt
      this.alarmCnt9 = this.fetchRequestAlarmsCnt

      if (this.alarmCnt1 > 0) this.isQuestionFlashing1 = true
      if (this.alarmCnt2 > 0) this.isQuestionFlashing2 = true
      if (this.alarmCnt3 > 0) this.isQuestionFlashing3 = true
      if (this.alarmCnt4 > 0) this.isQuestionFlashing4 = true
      if (this.alarmCnt5 > 0) this.isQuestionFlashing5 = true
      if (this.alarmCnt6 > 0) this.isQuestionFlashing6 = true
      if (this.alarmCnt7 > 0) this.isQuestionFlashing7 = true
      if (this.alarmCnt8 > 0) this.isQuestionFlashing8 = true
      if (this.alarmCnt9 > 0) this.isQuestionFlashing9 = true
    },
    flashAvatar(target) {
      if (target === 1) {
        this.isQuestionFlashing1 = true
      }
      if (target === 2) {
        this.isQuestionFlashing2 = true
      }
      if (target === 3) {
        this.isQuestionFlashing3 = true
      }
      if (target === 4) {
        this.isQuestionFlashing4 = true
      }
      if (target === 5) {
        this.isQuestionFlashing5 = true
      }
      if (target === 6) {
        this.isQuestionFlashing6 = true
      }
      if (target === 7) {
        this.isQuestionFlashing7 = true
      }
      if (target === 8) {
        this.isQuestionFlashing8 = true
      }
      if (target === 9) {
        this.isQuestionFlashing9 = true
      }
    },
    clickHandler(target) {
      if (target === 1) {
        this.$router.push({ name: 'deposit-list' }).catch(() => {})
      }
      if (target === 2) {
        this.$router.push({ name: 'exchange-list' }).catch(() => {})
      }
      if (target === 3) {
        this.$router.push({ name: 'messages-history-user' }).catch(() => {})
      }
      if (target === 4) {
        this.$router.push({ name: 'users-Signup-list' }).catch(() => {})
      }
      if (target === 5) {
        if (this.$route.name !== 'refund-hit-result') {
          this.$router.push({ name: 'refund-hit-result' }).catch(() => {})
        } else {
          // 중복 Router 동작 우회로 EventBus 사용함
          this.$EventBus.$emit('QuickBtnFetchREQUEST')
        }
      }
      if (target === 6) {
        this.$router.push({ name: 'branch-cashout-list' }).catch(() => {})
      }
      if (target === 7) {
        // 고액 알람은 실시간 휘발성 알람으로 로컬스토리지를 사용 (클릭시 0으로 초기화함)
        this.alarmCnt7 = 0
        localStorage.removeItem('alarmCnt7')
        this.isQuestionFlashing7 = false
        if (this.$route.name !== 'refund-hit-result') {
          this.$router.push({ name: 'refund-hit-result', params: { targetTab: '대기함', targetOption: 'YET' } }).catch(() => {})
        } else {
          // 중복 Router 동작 우회로 EventBus 사용함
          this.$EventBus.$emit('QuickBtnFetchYET')
        }
      }
      if (target === 8) {
        this.$router.push({ name: 'notice-admin' }).catch(() => {})
      }
      if (target === 9) {
        this.$router.push({ name: 'messages-guest' }).catch(() => {})
      }
    },
    showToast(title, subtitle, position, targetRoute) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text: subtitle,
          icon: 'BellIcon',
          variant: 'success',
        },
      },
      {
        position,
        timeout: 7000,
        // onClick 이벤트가 Toast 외부에서 처리되지 않는 경우 옵션을 추가
        onClick: () => {
          // 클릭 시 라우터 이동 처리
          this.$router.push(targetRoute).catch(() => {})
        },
      })
    },
  },
}
</script>

<style scoped>
.avtBtn {
  border-radius: 50%!important;
}
.button-container {
  display: flex;          /* Flexbox를 사용하여 자식 요소들을 가로로 정렬 */
  gap: 0.3rem;            /* Flexbox의 gap 속성을 사용하여 요소들 사이의 간격을 설정 */
  align-items: center;    /* 선택적: 버튼들을 수직으로 중앙 정렬 (필요에 따라 적용) */
  flex-direction: row;    /* 기본값, 항목들을 가로로 정렬 */
  flex-wrap: wrap;
}

@keyframes spinAndJump {
  0%, 100% {
    transform: rotateY(0deg) translateY(0);
  }
  /*10% {*/
  /*  transform: rotateY(0deg) translateY(-20px); !* 최고점에 도달 *!*/
  /*}*/
  20% {
    transform: rotateY(0deg) translateY(0); /* 떨어져서 다시 원래 위치 */
  }
  /*30% {*/
  /*  transform: rotateY(0deg) translateY(-20px); !* 다시 점프하여 최고점에 도달 *!*/
  /*}*/
  40% {
    transform: rotateY(0deg) translateY(0); /* 다시 떨어져서 원래 위치 */
  }
  /*50% {*/
  /*  transform: rotateY(0deg) translateY(-20px);*/
  /*}*/
  60% {
    transform: rotateY(0deg) translateY(0);
  }
  /*70% {*/
  /*  transform: rotateY(0deg) translateY(-20px);*/
  /*}*/
  80% {
    transform: rotateY(0deg) translateY(0);
  }
  90% {
    transform: rotateY(270deg) translateY(0);
  }
}

@keyframes flash {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.avtBtn .flashing {
  animation: spinAndJump 3s ease-in-out infinite; /* ease-in-out로 점프와 착지가 자연스럽게 보이게 함 */
  transform-style: preserve-3d;
  background-color: rgba(23, 148, 227, 1);
}

.avtBtn:hover .flashing {
  /*animation: none;  !* 마우스가 올라갔을 때 애니메이션을 없애줍니다. *!*/
  /*transform: none;  !* 만약 이전의 transform 효과가 남아있다면 이를 초기화 *!*/
  animation: none;
  transform: scale(1.2); /* 이미지를 1.1배 확대 */
  transition: transform 0.3s ease-in-out; /* 트랜지션 효과 추가 */
}
/* 화면 너비가 sm 이하일 때 pr-2의 우측 패딩을 제거 */
@media (max-width: 576px) {
  .button-container .pr-2 {
    padding-right: 0 !important;
  }
}

</style>
